<template>
  <div>
    <div class="main-header">
      <div class="title">语音求助列表</div>
    </div>
    <div class="main-main">
      <a-card>
        <div class="optionsAndPagination"></div>
        <div class="actions">
          <div></div>
          <Pagination
            @refresh="refresh"
            @pageChange="pageChange"
            @pageSizeChange="pageSizeChange"
            :total="total"
            :page="searchOptions.page"
          ></Pagination>
        </div>
        <div>
          <a-table
            size="middle"
            :loading="tableLoading"
            :data-source="tableData"
            :columns="tableColumns"
            :pagination="false"
            :scroll="{ x: 10 }"
          >
            <template slot="addTime" slot-scope="addTime">
              {{ $dayjs(addTime).format("YYYY-MM-DD") }}
            </template>
            <template slot="voice" slot-scope="row">
              <a @click="playVoice(row)"> 播放</a>
            </template>
            <template slot="action" slot-scope="row">
              <div style="display: flex; gap: 10px; justify-content: center">
                <a @click="delVoice(row)">删除</a>
              </div>
            </template>
          </a-table>
        </div>
      </a-card>
    </div>
    <audio :src="voiceSrc" style="display: none" id="voice"></audio>
  </div>
</template>

<script>
import { getVoice, delVoice } from "@/network/api";
import Pagination from "../components/Pagination";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      voiceSrc: "",
      tableLoading: false,
      total: 0,
      tableData: [
      ],
      searchOptions: {
        page: 1,
        pageSize: 20,
      },
    };
  },
  methods: {
    async getVoice(
      params = {
        page: 1,
        pageSize: 20,
      }
    ) {
      this.tableLoading = true;
      var index =
        (this.searchOptions.page - 1) * this.searchOptions.pageSize + 1;
      const { data } = await getVoice(params);
      this.tableData = data.content.map((item) => {
        item.index = index;
        index++;
        return item;
      });
      this.tableLoading = false;
      this.total = data.total;
    },
    delVoice(row) {
      this.$confirm({
        title: "确认删除该语音吗？",
        onOk: () => {
          return new Promise((resolve, reject) => {
            delVoice({ id: row.id }).then((res) => {
              if (res.status == 0) {
                this.$message.success("删除成功！");
                this.getVoice();
                resolve()
              }else{
                this.$message.error('删除失败！')
              }
            });
          });
        },
      });
    },
    playVoice(row) {
      document.getElementById("voice").pause();
      this.voiceSrc = row.voiceUrl;
      this.$nextTick(() => {
        document.getElementById("voice").play();
      });
    },
    pageChange(page, pageSize) {
      this.searchOptions.page = page;
      this.getVoice(this.searchOptions);
    },
    pageSizeChange(page, pageSize) {
      this.searchOptions.page = 1;
      this.searchOptions.pageSize = pageSize;
      this.getVoice(this.searchOptions);
    },
    refresh() {
      this.getVoice();
    },
  },

  created() {
    this.getVoice();
  },
  computed: {
    tableColumns() {
      return [
        { dataIndex: "index", title: " ", align: "center", width: 90 },
        {
          dataIndex: "nickName",
          title: "求助人名称",
          align: "center",
          width: 150,
        },
        {
          dataIndex: "addTime",
          title: "发布求助日期",
          align: "center",
          scopedSlots: { customRender: "addTime" },
          width: 550,
        },
        {
          title: "录音",
          align: "center",
          scopedSlots: { customRender: "voice" },
        },
        {
          title: "操作",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ];
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  font-size: 22px;
}
.date-picker {
  display: flex;
  align-items: center;
}

.ant-card-body {
  padding: 0.56rem 1.06rem;
}
.ant-tabs-bar {
  margin: 0;
}

.ant-tabs-tab {
  border-radius: none !important;
}

.ant-table-row-cell-break-word {
  text-align: center !important;
}

.pagination {
  display: flex;
}
.actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.actions .label {
  display: flex;
  align-items: center;
}
.actions .btns {
  display: flex;
  justify-content: space-between;
}
.excel-btn {
  padding: 0 !important;
  border: 0 !important;
  background-color: transparent !important;
  cursor: pointer;
  vertical-align: middle;
}
.excel-btn:after {
  top: 0;
  left: 0;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.ant-tabs-tab {
  margin-right: 0;
}

.search-card {
  margin-bottom: 10px;
}
.admin-list {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 15px;
}
.admin-info {
  flex: 1;
  text-align: center;
}
</style>